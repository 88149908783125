<template>
  <div class="layoutContent">
    <div class="contentParty contentPartyWap">
      <Nav ref="nav" :tab-index="tabIndex">
        <template #basic-info><BasicInfo /> </template>
        <template #change-password><ChangePassword /> </template>
      </Nav>
    </div>
  </div>
</template>

<script>
import Nav from "./components/myprofile-nav";
import BasicInfo from "./basicinfo.vue";
import ChangePassword from "./change-password.vue";

export default {
  components: {
    Nav,
    BasicInfo,
    ChangePassword,
  },
  data() {
    return {
      tabIndex: this.$route.query.tabIndex || 0,
    };
  },
};
</script>

<style>
</style>