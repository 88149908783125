import { PW_Worker_Portal_Code } from "@/router/permissionCode";
import { g } from "fe-infrastractures";

export const MyProfileTabs = (route, userAccountType, enumUserAccountType) => {
  let item = [
    {
      title: g("basic-info"),
      index: 0,
      route: "basic-info",
      permissionCode: PW_Worker_Portal_Code.MyProfile.BasicInformation,
      breadcrumb: g("breadcrumb.basic-info"),
    },
  ];
  if (userAccountType == enumUserAccountType.member) {
    item.push(
      {
        title: g("change-password"),
        index: 1,
        route: "change-password",
        permissionCode: PW_Worker_Portal_Code.MyProfile.ChangePassword,
        breadcrumb: g("breadcrumb.change-password"),
      });
  }
  return item;
};
