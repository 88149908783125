<template>
  <div>
    <b-tabs v-model="tabindex" nav-wrapper-class="contentNavTabWap">
      <b-tab
        v-for="(item, index) in items"
        :title="item.title"
        :key="index"
        @click="clickTab(item)"
      >
        <slot :name="item.route" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { getTabIndex, handleTabs } from "@/utils/permission";
import { getUserType } from "@/api/user";
import { enumUserAccountType } from "@/utils/enums/enumUserAccountType";
import { MyProfileTabs } from "./myProfileTabs";
export default {
  data() {
    return {
      tabindex: this.tabIndex,
      items: [],
      userAccountType: {},
    };
  },
  methods: {
    clickTab(item) {
      let breadcrumbName = item.breadcrumb;
      document
        .getElementsByClassName("breadcrumb")[0]
        .querySelectorAll('span[aria-current="location"]')[0].innerHTML =
        breadcrumbName;
      return;
    },
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.userAccountType = enumUserAccountType;
    this.items = handleTabs(
      MyProfileTabs(this.$route, parseInt(getUserType()), this.userAccountType),
      this
    );
    this.tabindex = getTabIndex(this.items, null, this);
  },
};
</script>